// Cache break version: v1
// We can update the above comment to force assets to recompile. See #19191

/**
 * lazysizes
 *
 * lazysizes is a self-initializing image lazyloader, and can be used to
 * lazy-load scripts, widgets, and other functionality.
 *
 * It also automatically calculate the `sizes` attribute for responsive images,
 * and can be extended with optional plugins.
 *
 * See: https://github.com/aFarkas/lazysizes
 */

import lazySizes from 'lazysizes'

lazySizes.cfg.lazyClass = 'js-lazyload'
